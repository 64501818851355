<template>
    <section class="groups container-fluid d-flex flex-column h-100 p-3">
        <div class="row h-100 m-0">
            <div class="col-lg-7 col-xl-8 h-100 overflow-auto">
                <div class="position-sticky title d-flex flex-nowrap justify-content-between align-items-end pb-3">
                    <div class="d-flex flex-nowrap align-items-center">
                        <div class="d-flex d-xl-none align-items-center">
                            <button-toggle-side-bar></button-toggle-side-bar>
                        </div>
                        <h1 class="d-none d-lg-block pl-3 pl-xl-0 mb-0 mr-2">
                            <span class="badge badge-dark">
                                <span v-if="keyword.length > 1">{{ displayed.length }}/</span>{{ chats.length }}
                            </span>
                        </h1>
                        <h1 class="d-lg-none h2 pl-3 pl-xl-0 mb-0 mr-2">
                            <span class="badge badge-dark">
                                <span v-if="keyword.length > 1">{{ displayed.length }}/</span>{{ chats.length }}
                            </span>
                        </h1>
                        <h1 class="d-none d-lg-block display-4 font-weight-bolder flex-grow-1 mb-0">
                            Chats
                        </h1>
                        <h1 class="d-lg-none font-weight-bolder flex-grow-1 mb-0">
                            Chats
                        </h1>
                    </div>
                    <div class="d-flex flex-nowrap">
                        <search-bar class="search-bar mr-1"
                                    v-model="keyword">
                        </search-bar>
                        <button class="btn btn-light text-nowrap rounded-pill shadow"
                                v-on:click="isModalChatOpen = true">
                            ➕ Create
                        </button>
                    </div>
                </div>

                <div class="row">
                    <chat-card class="col-xl-6 px-2 mb-3"
                               v-for="chat in displayed"
                               v-bind:key="chat._id"
                               v-bind:type="chat?.type ? chat.type : 'whatsapp'"
                               v-bind:chat="chat">
                    </chat-card>
                </div>
            </div>
            <div class="col-lg-5 col-xl-4 mx-3 mx-lg-0 p-lg-0 h-100">
                <router-view></router-view>
            </div>
        </div>

        <modal-chat v-model="isModalChatOpen"></modal-chat>
    </section>
</template>

<script>
export default {
    name: "Chats",
    metaInfo () {
        return {
            "title": "Chats",
        };
    },
    components: {
        ButtonToggleSideBar: () => import("@/components/layout/ButtonToggleSideBar.vue"),
        ChatCard: () => import("@/components/Chats/ChatCard.vue"),
        SearchBar: () => import("@/components/layout/SearchBar.vue"),
        ModalChat: () => import("@/components/Chats/ModalChat.vue"),
    },
    data () {
        return {
            isModalChatOpen: false,
            keyword: "",
            results: [],
        };
    },
    computed: {
        chats () {
            return this.$store.getters["whatsapp/chats"];
        },
        displayed () {
            if (this.keyword.length > 1) {
                return this.results;
            }
            return this.$store.getters["whatsapp/chats"];
        },
        fuse () {
            return this.$store.getters["whatsapp/fuse"];
        },
    },
    methods: {

    },
    watch: {
        keyword (newVal) {
            if (newVal.length > 1) {
                this.results = this.fuse
                    .search(newVal)
                    .map(
                        ({ item }) => item
                    );
            }
        }
    },
}
</script>

<style lang="scss" scoped>
.title {
    top: 0;
    background-color: rgba(255, 255, 255, 1);
    z-index: 100;
}
</style>
